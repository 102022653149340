body {
    .buorg {
        height: 100%;
        border-bottom: none;
        background-color: rgba(0, 0, 0, 0.5);
        text-align: left;
        font: inherit;
        box-shadow: none;
        animation: .3s ease-out animate-wrapper;
        transition: transform .3s ease-out,
        -webkit-transform .3s ease-out;
        
        & > .buorg-pad > .buorg-icon {
            display: none;
        } 

        .modal-footer {
            flex-wrap: wrap;
        }
        .modal-dialog {
            animation: .8s ease-out animate-modal;
        }

        #buorgig,
        #buorgul,
        #buorgpermanent {
            margin: .25rem 0;
            box-shadow: none;
        }
        
        #buorgul {
            margin-left: .5rem;
        }
    }
    
 
    
    @keyframes animate-wrapper {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes animate-modal {
        0% {
            opacity: 0;
            transform: translate(0, -25%);
        }
        75% {
            opacity: 1;

        }
        100% {
            transform: translate(0, 0);
        }
    }

    
}